.footer-container{
    background-color: black;
    min-height:230px;
    width:100%;
}
.footer-text {
    color: #686868 !important;
}
.footer-hr {
    display: block;
    height: 20px !important;
    border-style: inset !important;
    border-width: 1px !important;
    margin: 4px 4px !important;
}

.footer-link {
    width: 30%;
}

.text-center{
    text-align: center;
}
@media(max-width: 767px){
    .copy-right-text{
        text-align: center;
    }
}
@media(max-width: 375px){
    .footer-text {
        font-size: 14px;
    }
}