.contact-us-icon {
    width: 28px;
    height: 29px;
}

.contact-us-body {
}

.contact-us-form-error {
    color: red;
}

.contact-us-btn {
    border: 2px solid #f05422 !important;
    border-radius: 20px !important;
    margin-top: 20px !important;
    padding: 5px 15px;
}

.contact-us-btn:hover {
    background-color: #f05422 !important;
    color: white !important;
}

.contact-info-map{
    margin-bottom: 20px;
}