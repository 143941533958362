.pos-menu_img {
    float:left;
    width:50%;
    margin-bottom:20px;
}

.pos-menu_img > img {
    border: 2px solid black;
}

.my-table {
    width:100%;
}

.my-table-description{
    width:80%;
}

.my-table-ref-img{
    width:20%;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

img#labpay-info {
    padding-bottom: 20px;
}

.section {
    margin-bottom: 20px;
    float:left;
}

table > thead > tr > th {
	padding-left: 10px;
    padding-right: 10px;
    border: 1px solid black;
}

table > tbody > tr > td {
	border-left: 1px solid black;
	border-right: 1px solid black;
	padding-left: 10px;
    padding-right: 10px;
    border-top:1px solid black;
}

table tr:last-child > td{
	border-bottom: 1px solid black;
}


@media screen and (max-width: 424px) and (min-width: 320px) {
    .pos-menu_img {
        width: 100%;   
    }
}