/* Background container */
.bg-white-container{
    background-color: white;
}
.bg-grey-container{
    background-color: #f4f4f4;
}

/* Advantage section */
.box-container {
    text-align: center;
    display: block;
    /*
    box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25), 0 8px 16px -8px rgba(0,0,0,0.3), 0 -6px 16px -6px rgba(0,0,0,0.025);
    transform: translate3d(0, -2px, 10px);
    */
}

.box-img-container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.box-img-container img {
    width:60px;
    height:60px;
}

.box-content {
    padding: 20px;
}
/* container section */
.home-container {
    min-height: 300px;
    margin-bottom: 40px;
    /*box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25), 0 8px 16px -8px rgba(0,0,0,0.3), 0 -6px 16px -6px rgba(0,0,0,0.0);*/
}

.integration-container {
    background-color: white;
}

/* Service section */
.service-img-container {
    text-align: center;
    display: block;
}

.href-img:hover {
    cursor: pointer;
}

.service-img-container img {
    width: 360px;
    height: 240px;
}

/* Integration section */
#integration-logos ul {
    display: flex;
    padding-left: 0px;
}

#integration-logos li {
    margin: 0px;
    list-style: none;
}
#integration-logos img {
    height: 60px;
    vertical-align: middle;
    margin: 0px 20px;
}

/* Merchant section */
#merchants-logos {
    width: 100%;
    text-align:center;
}
.merchants {
    width: 840px;
    height: 504px;
    text-align: center;
}

/* Common section */
.learn-more-link {
    color: #F05422;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
}
.learn-more-link:hover {
    color: black !important;
    text-decoration: none;
}

.stay-connected-statement {
    font-size: 28px;
    font-weight: 600;
    margin: 10px 0px;
    text-transform:uppercase;
}

.stay-connected-btn-container {
    float: left;
}

.home-stay-connected-form-error {
    color: red;
}

.home-newsletter-btn {
    border: 2px solid #f05422 !important;
    border-radius: 20px !important;
    padding: 5px 15px;
}

.home-newsletter-btn:hover {
    background-color: #f05422 !important;
    color: white !important;
}



@media (max-width: 767px ) {
    #integration-logos ul {
        display: block;
    }
} 