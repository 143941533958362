hr {
    display: block;
    height: 20px !important;
    border-style: inset !important;
    border-width: 1px !important;
    margin: 8px 4px !important;
}

.dropdown {
    padding: 0px;
}

/* Navbar (non-scroll) */
.my-nav {
    background-color: white;
}
.nav-link{
    color: #777 !important;
}

/* Navbar link */
.navbar__link {
    cursor: pointer;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    padding-right: .5rem;
    padding-left: .5rem;
}

.navbar__link:hover {
    transform: translateY(-2px);
    border-bottom-color: #F05422;
}

.navbar__link--active {
    border-bottom: 3px solid #F05422;
    transition: border-bottom .5s ease-in-out;
}

.navbar__link.active, a.active-link {
    color: #F05422 !important;
}

/* Language dropdown */
#language-dropdown-menu {
    left: -200% !important;
}

@media (min-width: 768px ) {
    nav {
        padding: 0px 50px !important;
    }
    .navbar__link{
        font-size: 14px;
    }
}

@media (max-width: 767px ) {
    hr {
        display:none;
    }
    #language-dropdown-menu {
        left: 0% !important;
    }
} 