/* clear */
.clear-bottom{
    margin-bottom: 20px;
}

.clear-top{
    margin-top: 20px;
}

/* container */
.left-right-container {
    min-height: 280px;
}

.left-container {
    width: 50%;
    float: left;
}

.right-container {
    width: 50%;
    float: right;
}

/* section */
.section-header-container {
    margin-bottom:40px;
}

.section-header {
    font-size: 28px;
    font-weight: 600;
    margin: 10px 0px;
    text-align: center;
    text-transform:uppercase;
}

.section-header::after {
    content: "";
    position: absolute;
    left: 45%;
    width: 10%;
    height: 3px;
    bottom: 0;
    background: linear-gradient(to right, #F05422 98%,grey 2%);
}

.section-content-title {
    font-size: 22px;
    font-weight: 400;
    margin : 10px 0px;
    margin-top: 0px;
}

.section-content {
    font-size: 18px;
    font-weight: 400;
}

/* button */
.my-button {
    color: #F05422 !important;
    border-radius: 50px;
    border-color: #F05422 !important;
    transition: all 0.3s ease 0s;
    background: none;
}

.my-button:hover{
    color: white !important;
    border-color: #F05422 !important;
    background-color: #F05422 !important;
}


@media (max-width: 991px ) {
    /* container */
    .left-right-container {
        min-height: 200px;
    }
} 

@media (max-width: 767px ) {
    /* container */
    .left-right-container {
        min-height: 140px;
    }
} 