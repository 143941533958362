@media (max-width: 991px ) {
    .box-img-container img {
        width: 210px;
        height: 75px;
    }
} 

@media (max-width: 767px ) {
    .box-img-container img {
        width: 150px;
        height: 53.5px;
    }
} 

@media (max-width: 575px ) {
    .box-img-container img {
        width: 280px;
        height: 100px;
    }
} 