.pos-feature-img-container {
    display:table-cell; 
    vertical-align:middle; 
    text-align:center;
}

.pos-feature-img-container img {
    width: 420px;
    height: 280px;
}

.pos-feature-content {
    text-align: center;
    vertical-align: middle;
    line-height: 280px;
}

@media (max-width: 991px ) {
    .pos-feature-img-container img {
        width: 300px;
        height: 200px;
    }

    .pos-feature-content {
        line-height: 200px;
    }
} 

@media (max-width: 767px ) {
    .pos-feature-img-container img {
        width: 210px;
        height: 140px;
    }

    .pos-feature-content {
        line-height: 140px;
        font-size: 16px;
    }
} 

@media (max-width: 575px ) {
    .pos-feature-img-container img {
        width: 210px;
        height: 140px;
    }

    .pos-feature-content {
        line-height: 140px;
        font-size: 14px;
    }
} 