/* color css */
.base-color-grey {
    color: #f4f4f4;
}
.base-color-deep-grey {
    color: #d3d3d3;
}
.base-color-black {
    color: #212529;
}
.base-bg-color-grey {
    background-color: #f4f4f4;
}
.base-bg-color-deep-grey {
    background-color: #d3d3d3;
}
.base-color-orange {
    color: #F05422;
}
.base-bg-color-orange {
    background-color: #F05422;
}
.base-color-blue {
    color: #0D4673;
}
.base-bg-color-blue {
    background-color: #0D4673;
}