.merchants-logo {
    max-width:240px;
    margin: 20px;
    display:inline-block;
}

#merchants-logo-container {
    margin-left:10%;
    width:80%;
    text-align: center;
}

@media (max-width: 767px ) {
    #merchants-logo-container {
        margin-left:0%;
        width:100%;
    }
} 