/* NotoSerif */
@font-face {
    font-family: "Noto Serif";
    src: url("Noto_Serif/NotoSerif-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Noto Serif";
    src: url("Noto_Serif/NotoSerif-BoldItalic.ttf");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Noto Serif";
    src: url("Noto_Serif/NotoSerif-Italic.ttf");
    font-weight: normal;
    font-style: italic, oblique;
}

@font-face {
    font-family: "Noto Serif";
    src: url("Noto_Serif/NotoSerif-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

.font-face-en-US {
    font-family: 'Noto Serif', serif;
    font-weight: 500;
}

/* NotoSerif TC */
@font-face {
    font-family: "Noto Serif TC";
    src: url("./Noto_Serif_TC/NotoSerifTC-Regular.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Noto Serif TC";
    src: url("./Noto_Serif_TC/NotoSerifTC-Bold.otf") format('opentype');
    font-weight: bold;
    font-style: normal;
}

.font-face-zh-HK {
    font-family: 'Noto Serif TC', serif;
    font-weight: 500;
}

/* NotoSerif SC */
@font-face {
    font-family: "Noto Serif SC";
    src: url("./Noto_Serif_SC/NotoSerifSC-Regular.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Noto Serif SC";
    src: url("./Noto_Serif_SC/NotoSerifSC-Bold.otf") format('opentype');
    font-weight: bold;
    font-style: normal;
}

.font-face-zh-CN {
    font-family: 'Noto Serif SC', serif;
    font-weight: 500;
}
