.sitemap-container {
    padding : 50px;
    min-height : 200px;
    width : 100%;
    text-align: center;
}

.sitemap-text {
}
.sitemap-header {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 10px;
}

.sitemap-link {
    font-size: 16px;
    color: rgba(0,0,0,.5) !important;
}

@media (max-width: 575px) { 
    .sitemap-sub-container {
        margin-bottom: 20px;
    }
}